import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

import bus from "@/plugins/bus";
import WarehouseMap from "@/components/WarehouseMap";
import { getWarehousePersonList } from "@/api/uac/user";
export default {
  name: "carWarehouseAdd",
  mixins: [mixins],
  components: {
    WarehouseMap
  },
  data() {
    return {
      storemanList: [],
      areas: [],
      //区域
      areaProps: {
        value: 'id',
        label: 'name',
        emitPath: false
      },
      address: null,
      parkType: '定位范围',
      type: 'ADD',
      dialogVisible: false,
      //地图选点
      map: null,
      edit: false,
      formModel: {
        id: "",
        name: "",
        areaId: "",
        administratorId: [],
        administratorMobile: "",
        longitude: "",
        latitude: "",
        address: ""
      },
      mySet: null,
      formRules: {
        name: [{
          required: true,
          message: '请输入仓库名称!',
          trigger: 'blur'
        }],
        areaId: [{
          required: true,
          message: '请选择所属区域!',
          trigger: 'blur'
        }],
        administratorId: [{
          required: true,
          message: '请输入管理员名称!',
          trigger: 'blur'
        }],
        // administratorMobile: [{
        //     required: true, message: '请输入管理员电话号码!', trigger: 'blur'
        // }],
        longitude: [{
          required: true,
          message: '请输入经度!',
          trigger: 'blur'
        }],
        latitude: [{
          required: true,
          message: '请输入纬度!',
          trigger: 'blur'
        }]
        // address: [{
        //     required: true, message: '请输入仓库详细地址!', trigger: 'blur'
        // }]
      }
    };
  },

  methods: {
    //选择管理员事件
    selectAdministrators() {
      this.getUserPhone();
    },
    clearData() {
      this.getUserPhone();
    },
    //查询手机号
    getUserPhone() {
      let phoneNo = "";
      let administratorId = this.formModel.administratorId;
      if (administratorId.length > 0) {
        for (let i = 0; i < administratorId.length; i++) {
          phoneNo += this.mySet.get(administratorId[i]) + ",";
        }
        this.formModel.administratorMobile = phoneNo.substring(0, phoneNo.length - 1);
      } else {
        this.formModel.administratorMobile = "";
      }
    },
    //查询仓库管理员列表
    getWarehousePersonList(e) {
      let param = {
        id: e
      };
      getWarehousePersonList(param).then(res => {
        if (res.data.length > 0) {
          this.storemanList = res.data;
          this.mySet = new Map();
          this.storemanList.forEach(item => {
            this.mySet.set(item.id, item.userPhone);
          });
        }
      });
    },
    getAreas() {
      this.areas = [];
      this.$api.uac.area.list().then(res => {
        this.areas = res.data;
      });
    },
    closeMap() {
      this.dialogVisible = false;
    },
    getMap() {
      if (this.formModel.areaId == "" || this.formModel.areaId == null) {
        ElMessage.error("请先选择一个区域,谢谢！");
        return;
      }
      this.dialogVisible = true;
      this.address = {
        lng: this.formModel.longitude,
        lat: this.formModel.latitude,
        add: this.formModel.address,
        areaId: this.formModel.areaId,
        id: this.formModel.id,
        title: this.formModel.name
      };
    },
    getAddressData(val) {
      this.formModel.longitude = val.lng;
      this.formModel.latitude = val.lat;
      this.formModel.address = val.address;
    },
    //验证手机号格式是否正确
    phoneCheck(rule, value, callbackFn) {
      // const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/  // 手机号正则
      // 电话号码正则表达式（支持手机号码，3-4位区号，7-8位直播号码，1－4位分机号）
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg.test(value)) {
        callbackFn('手机号码格式不正确');
        return;
      }
      callbackFn();
    },
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
              if (this.$route.meta && this.$route.meta.parent) {
                bus.emit(this.$route.meta.parent, true);
              }
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.edit) {
            this.$api.business.carWarehouse.update(this.formModel).then(callBack);
          } else {
            this.$api.business.carWarehouse.create(this.formModel).then(callBack);
          }
        }
      });
    }
  },
  beforeMount() {
    this.$nextTick(() => {
      this.getAreas();
      if (this.$route.query.id) {
        this.edit = true;
        this.type = 'EDIT';
        const loading = ElLoading.service();
        this.$api.business.carWarehouse.detail(this.$route.query.id).then(res => {
          if (res.code === 200) {
            this.$nextTick(() => {
              this.getWarehousePersonList(res.data.areaId);
              this.formModel = res.data;
            });
          } else if (res.code === 121601) {
            this.$router.replace({
              name: '404'
            });
          } else {
            ElMessage.error(res.message);
            this.$router.back();
          }
        }).finally(() => {
          loading.close();
        });
      }
    });
  }
};